import React from "react";
import '../Modal/Modal.scss';

interface ModalProps  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
  };

export const Modal: React.FC<ModalProps> = ({ isOpen, setIsOpen, children }) => {

    return (
        <div className={isOpen ? "modal modal-active" : "modal"} 
        // onClick={() => setIsOpen(false)}
        >
        <div className="container">
            <div className="modal-container">
                <div className="modal__content" onClick={event => event.stopPropagation()}>
                    {children}
                </div>
            </div>
        </div>
    </div>
    )
  }
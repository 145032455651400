import React, { ReactNode, useState } from "react";
import '../Toggle/Toggle.scss';

interface ToggleProps {
  labelLeft: string | ReactNode;
  labelRight: string | ReactNode;
  textLeft?: string | ReactNode;
  textRight?: string | ReactNode;
  defaultChecked?: boolean;
  onChange: (value: boolean) => void;
  setUserType: any;
}

const Toggle: React.FC<ToggleProps> = ({ labelLeft, labelRight, onChange, defaultChecked = false, textLeft, textRight, setUserType}) => {
  const [isActive, setIsActive] = useState(defaultChecked);

  const handleClick = () => {
    if (isActive) {
      setUserType(1);
    } else {
      setUserType(2);
    }
    setIsActive(!isActive);
    onChange(!isActive);
  };

// const [checked, setChecked] = useState(defaultChecked);

// const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//   const isChecked = event.target.checked;
//   setChecked(isChecked);
//   if (onChange) {
//     onChange(isChecked);
//   }
// };

  return (
    <div className='toggle-wrapp'>
      <span className={`toggle__text ${!isActive ? "toggle--active-text" : "toggle--inactive"}`}>{textLeft}</span>
        <div className={`toggle ${isActive ? "toggle--active" : "toggle--inactive"}`} onClick={ handleClick}>
            <span className="toggle__label">{isActive ? labelRight : labelLeft}</span>
        </div>
      {/* <span className="toggle__text">{isActive ? textRight : textLeft}</span> */}
      <span className={`toggle__text ${isActive ? "toggle--active-text" : "toggle--inactive"}`}>{textRight}</span>
    </div>
  );
};

export default Toggle;
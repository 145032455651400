import { Outlet, useLocation } from 'react-router-dom';
import { Header } from './Header/Header';
import { Warning } from './Warning/Warning';
import { useEffect } from 'react';

export function Layout() {
    const location = useLocation();
    const { pathname } = useLocation();

    useEffect(() => {
        // Прокручиваем страницу к началу при изменении пути
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [pathname]);
    
    return (
        <>
            {/* <Warning/> */}
            <Header />
            <Outlet />
        </>

    )
}
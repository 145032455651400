import React, { useState, useRef, useEffect } from "react";
import '../Header/Header.scss';

export function Header() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const handleMouseEnterHelp = () => {
    setIsHelpVisible(true);
  };

  const handleMouseLeaveHelp = (event: React.MouseEvent) => {
    const isMouseOverItem = event.currentTarget.contains(event.relatedTarget as Node);
    if (!isMouseOverItem) {
      setIsHelpVisible(false);
    }
  };

  // const handleMouseLeaveHelp = (event: React.MouseEvent) => {
  //   const relatedTarget = event.relatedTarget as Node;
  //   if (relatedTarget && typeof relatedTarget.contains === 'function') {
  //     const isMouseOverItem = event.currentTarget.contains(relatedTarget);
  //     if (!isMouseOverItem) {
  //       setIsHelpVisible(false);
  //     }
  //   }
  // };
  
  if (!isNavVisible) {
    document.body.style.overflow = "auto";
  } else {
    document.body.style.overflow = "hidden";
  }

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        setIsNavVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="header__container container" ref={navRef}>
        <div className={`menu-trigger header__nav-burger ${isNavVisible ? 'active-burger' : ''}`} onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <a href="/" className="header__logo" onClick={() => setIsNavVisible(false)}>
          <svg className="logo-desctop" width="157" height="20" viewBox="0 0 157 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_312_241)">
              <path d="M64.8104 19.4762C62.6672 19.4762 60.7888 19.0694 59.1753 18.2557C57.6076 17.511 56.2875 16.3058 55.3799 14.7908C54.4724 13.2758 54.0178 11.5183 54.0729 9.73812C54.0172 7.95778 54.4716 6.20002 55.3792 4.68493C56.2868 3.16984 57.6072 1.96474 59.1753 1.22052C60.7934 0.406841 62.6718 0 64.8104 0C66.949 0 68.8312 0.406841 70.4569 1.22052C71.9968 1.97104 73.3017 3.15266 74.2229 4.63088C75.142 6.16272 75.611 7.93677 75.5731 9.73812C75.6109 11.5387 75.1419 13.312 74.2229 14.843C73.3015 16.3199 71.9966 17.4999 70.4569 18.2486C68.8495 19.0655 66.9674 19.4747 64.8104 19.4762ZM64.8104 14.8667C65.8558 14.8942 66.8942 14.6821 67.8507 14.2458C68.6607 13.8688 69.3452 13.2539 69.8186 12.4778C70.2935 11.6497 70.5323 10.7001 70.5075 9.73812C70.5323 8.77619 70.2935 7.82656 69.8186 6.99846C69.3452 6.22236 68.6607 5.60742 67.8507 5.23048C66.8911 4.81893 65.8622 4.60708 64.823 4.60708C63.7839 4.60708 62.7549 4.81893 61.7954 5.23048C60.9829 5.60844 60.295 6.22289 59.816 6.99846C59.3333 7.82368 59.0902 8.77454 59.1156 9.73812C59.0902 10.7017 59.3333 11.6526 59.816 12.4778C60.295 13.2534 60.9829 13.8678 61.7954 14.2458C62.7434 14.6805 63.7732 14.8926 64.8104 14.8667Z" fill="black" />
              <path d="M104.375 14.4944L102.132 16.8264V0.397949H107.126V19.0755H77.7109V0.397949H82.7054V16.8264L80.4619 14.4849H91.9433L89.9203 16.8264V0.397949H94.917V16.8264L92.8159 14.4849L104.375 14.4944Z" fill="black" />
              <path d="M110.139 19.0755V0.397949H115.135V17.3336L112.89 15.0182H119.937C120.757 15.0182 121.397 14.8025 121.854 14.3688C122.085 14.1474 122.266 13.8769 122.385 13.5761C122.504 13.2753 122.558 12.9514 122.543 12.6269C122.561 12.3016 122.508 11.9764 122.389 11.6747C122.27 11.373 122.087 11.1024 121.854 10.8826C121.395 10.4679 120.757 10.2593 119.937 10.2593H112.456V6.17588H120.252C121.609 6.13985 122.956 6.41675 124.197 6.98641C125.232 7.46979 126.109 8.25268 126.722 9.24023C127.325 10.2583 127.631 11.434 127.604 12.6269C127.63 13.8233 127.324 15.0023 126.722 16.0254C126.113 17.0165 125.235 17.8004 124.197 18.2792C122.954 18.8406 121.607 19.1125 120.252 19.0755H110.139ZM129.106 0.397949H134.103V19.0755H129.106V0.397949Z" fill="black" />
              <path d="M45.2144 12.2904L48.3901 8.26144V8.24485L54.5396 0.397949H49.2581L40.3256 12.1505V0.397949H35.3633V19.0755H39.8755L45.2075 12.2761L45.2144 12.2904Z" fill="black" />
              <path d="M51.2436 13.0894H45.6729L49.1173 19.0758H54.7891L51.2436 13.0894Z" fill="black" />
              <path d="M146.987 12.2904L150.163 8.26144L150.152 8.24485L156.31 0.397949H151.029L142.098 12.1505V0.397949H137.127V19.0755H141.639L146.98 12.2761L146.987 12.2904Z" fill="black" />
              <path className="color-black" d="M153.016 13.0894H147.447L150.892 19.0758H156.561L153.016 13.0894Z" fill="#AF0B0F" />
              <path className="color-black" d="M23.1075 5.05518V5.54813C23.1075 8.04032 22.1483 10.4304 20.4408 12.1927C18.7333 13.955 16.4175 14.945 14.0028 14.945C11.588 14.945 9.27219 13.955 7.56471 12.1927C5.85723 10.4304 4.89798 8.04032 4.89798 5.54813V5.05518H0V5.54813C0 9.38228 1.47577 13.0594 4.10266 15.7705C6.72955 18.4817 10.2924 20.0048 14.0074 20.0048C17.7223 20.0048 21.2852 18.4817 23.9121 15.7705C26.5389 13.0594 28.0147 9.38228 28.0147 5.54813V5.05518H23.1075Z" fill="#AF0B0F" />
              <path className="color-black" d="M20.0836 0H7.9248V5.0551H20.0836V0Z" fill="#AF0B0F" />
            </g>
            <defs>
              <clipPath id="clip0_312_241">
                <rect width="156.552" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg className="logo-mobile" width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.6337 6.50195V7.14488C30.6337 10.3953 29.362 13.5126 27.0984 15.8111C24.8348 18.1095 21.7647 19.4007 18.5635 19.4007C15.3623 19.4007 12.2922 18.1095 10.0285 15.8111C7.76494 13.5126 6.49326 10.3953 6.49326 7.14488V6.50195H0V7.14488C0 12.1456 1.95643 16.9414 5.4389 20.4775C8.92137 24.0135 13.6446 26 18.5696 26C23.4945 26 28.2178 24.0135 31.7002 20.4775C35.1827 16.9414 37.1391 12.1456 37.1391 7.14488V6.50195H30.6337Z" fill="#B10207" />
            <path d="M26.9255 0H10.2129V6.49935H26.9255V0Z" fill="#B10207" />
          </svg>
        </a>
        <nav className={`header__nav ${isNavVisible ? 'header__list-visible' : ''}`} ref={navRef}>
          <ul className="header__list">
            <li className="header__item">
              <a href="/catalog" className='header__link' onClick={toggleNav}>
                <span className="catalog-svg">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="svg-color-active" fillRule="evenodd" clipRule="evenodd" d="M1.6665 4.1665C1.6665 3.50346 1.9299 2.86758 2.39874 2.39874C2.86758 1.9299 3.50346 1.6665 4.1665 1.6665H15.8332C16.4962 1.6665 17.1321 1.9299 17.6009 2.39874C18.0698 2.86758 18.3332 3.50346 18.3332 4.1665V15.8332C18.3332 16.4962 18.0698 17.1321 17.6009 17.6009C17.1321 18.0698 16.4962 18.3332 15.8332 18.3332H4.1665C3.50346 18.3332 2.86758 18.0698 2.39874 17.6009C1.9299 17.1321 1.6665 16.4962 1.6665 15.8332V4.1665Z" fill="#31465A" />
                    <path d="M5.8335 5C5.8335 7.76167 7.69933 10 10.0002 10C12.301 10 14.1668 7.76167 14.1668 5H12.5002C12.5002 7.13833 11.1085 8.33333 10.0002 8.33333C8.89183 8.33333 7.50016 7.13833 7.50016 5H5.8335Z" fill="white" />
                  </svg>
                </span>
                Каталог
              </a>
            </li>
            <li className="header__item">
              <a href="/about" className="header__link" onClick={toggleNav}>Как работает</a>
            </li>

            <li className="header__item header__item-last display-none"
              onMouseLeave={handleMouseLeaveHelp}
              onMouseEnter={handleMouseEnterHelp}>
              <a href="/help" className="header__link" onClick={toggleNav} onMouseEnter={handleMouseEnterHelp}>Помощь</a>
              {isHelpVisible && (
                <div className="header__dropdown-wrapp-help" onMouseLeave={handleMouseLeaveHelp}>
                  <ul className="header__dropdown">
                    <li>
                      <a href='/help/faq' className="header__link header__link-dropdown">Частые вопросы</a>
                    </li>
                    <li>
                      <a href='/help' className="header__link header__link-dropdown">Пользовательское соглашение</a>
                    </li>

                    <li>
                      <a href='/help/support' className="header__link header__link-dropdown">
                        Служба поддержки
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </li>


            <li className="header__item">
              <a href='/help/faq' className={`header__link header__link-mobail`} onClick={toggleNav}>Частые вопросы</a>
            </li>
            <li className="header__item">
              <a href="/help" className={`header__link header__link-mobail`} onClick={toggleNav}>Пользовательское соглашение</a>
            </li>
            <li className="header__item">
              <a href='/help/support' className={`header__link header__link-mobail`} onClick={toggleNav}>Служба поддержки</a>
            </li>

          </ul>
        </nav>
        {/* {isAuth ?
                  <a href={userType === '1' ? '/customer/personal-cabinet' : '/seller/personal-cabinet'} className="header__link" onClick={() => setIsNavVisible(false)}>Личный кабинет</a>
                  : */}
        <a href='/login' className="header__btn" onClick={() => setIsNavVisible(false)}>Войти</a>
        {/* // } */}

      </div>
    </header>
  )
}

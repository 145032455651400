import React, { useEffect, useState } from 'react';
import image from '../../img/login-back.png';
import '../Login/Login.scss';
import { Link, useNavigate } from 'react-router-dom';
import { postLogin } from '../../helpers/api';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import Toggle from '../../components/Toggle/Toggle';
import { Loader } from '../../components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../store/slice';
import { getCookie } from '../../helpers/getCookie';
// import Cookies from 'js-cookie';

export function Login() {

    const [loading, setLoading] = useState(false);
    // Toggle

    const [userType, setUserType] = useState(1);
    const [isSeller, setIsSeller] = useState(false);

    const handleSellerChange = (value: any) => {
        setIsSeller(value);
        setStatus(0)
    };


    const [password, setPassword] = useState<string>("");

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const [status, setStatus] = useState<number>(0);
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');

    const [errorMess, setErrorMess] = useState('')

    const submitForm = (event: any) => {
        event.preventDefault()
        const formDate = new FormData(event.target);
        formDate.append('user_type', String(userType));
        setLoading(true);

        postLogin(formDate).then((response) => {
            if (response.status === 200) {
                // localStorage.setItem('user_type', response.data.user_type);
                setStatus(2);

                if (userType === 1) {
                    // dispatch(setAuth(true));
                    // setLoading(true);
                    setTimeout(() => {
                        navigate("/customer/personal-cabinet");
                        window.location.reload();
                        setLoading(false);
                    }, 600)
                } else if (userType === 2) {
                    // dispatch(setAuth(true));
                    // setLoading(true);
                    setTimeout(() => {
                        navigate("/seller/personal-cabinet");
                        window.location.reload();
                        setLoading(false);
                    }, 600)
                }

                // setTimeout(() => {
                //     navigate("/")
                //     window.location.reload()
                // }, 600)
            }
        })
            .catch(e => {
                // setStatus(1);
                // if (e.response.status === 401) {
                //     setStatus(1);
                //     // setLoading(true);
                //     // setTimeout(() => {
                //     //         setLoading(false);
                //     // }, 3000)
                //     return
                // }
                if (e.response.status === 500) {
                    // setLoading(true);
                    setTimeout(() => {
                        setStatus(3);
                        setLoading(false);
                    }, 3000)
                    return
                }
                if (e.response.status === 403) {
                    // setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                        setErrorMess(e.response.data.message)
                        setStatus(5);
                    }, 1000)
                } else {
                    setTimeout(() => {
                        setStatus(1);
                        setLoading(false);
                    }, 1000)

                }
            })
    }

    return (
        <section className='login'>
            <div className='login__container'>
                {loading && <Loader loading={loading} />}

                <div className='login-wrapp-right'>
                    <img src={image} alt="" />
                </div>
                <div className='login-wrapp-left'>
                    <div className='form-wrapp'>
                        <h1 className='login__title'>Вход</h1>
                        {status === 2 && <div className="auth-success">
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.3 8.8L8.825 5.275L8.125 4.575L5.3 7.4L3.875 5.975L3.175 6.675L5.3 8.8ZM6 11.5C5.30833 11.5 4.65833 11.3687 4.05 11.106C3.44167 10.8433 2.9125 10.4872 2.4625 10.0375C2.0125 9.5875 1.65633 9.05833 1.394 8.45C1.13167 7.84167 1.00033 7.19167 1 6.5C1 5.80833 1.13133 5.15833 1.394 4.55C1.65667 3.94167 2.01283 3.4125 2.4625 2.9625C2.9125 2.5125 3.44167 2.15633 4.05 1.894C4.65833 1.63167 5.30833 1.50033 6 1.5C6.69167 1.5 7.34167 1.63133 7.95 1.894C8.55833 2.15667 9.0875 2.51283 9.5375 2.9625C9.9875 3.4125 10.3438 3.94167 10.6065 4.55C10.8692 5.15833 11.0003 5.80833 11 6.5C11 7.19167 10.8687 7.84167 10.606 8.45C10.3433 9.05833 9.98717 9.5875 9.5375 10.0375C9.0875 10.4875 8.55833 10.8438 7.95 11.1065C7.34167 11.3692 6.69167 11.5003 6 11.5Z" fill="#078550" />
                            </svg>
                            Авторизация выполнена успешно</div>}
                        {status === 1 && <div className="auth-bad">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 8.5C6.14167 8.5 6.2605 8.452 6.3565 8.356C6.4525 8.26 6.50033 8.14133 6.5 8C6.5 7.85833 6.452 7.7395 6.356 7.6435C6.26 7.5475 6.14133 7.49967 6 7.5C5.85833 7.5 5.7395 7.548 5.6435 7.644C5.5475 7.74 5.49967 7.85867 5.5 8C5.5 8.14167 5.548 8.2605 5.644 8.3565C5.74 8.4525 5.85867 8.50033 6 8.5ZM5.5 6.5H6.5V3.5H5.5V6.5ZM6 11C5.30833 11 4.65833 10.8687 4.05 10.606C3.44167 10.3433 2.9125 9.98717 2.4625 9.5375C2.0125 9.0875 1.65633 8.55833 1.394 7.95C1.13167 7.34167 1.00033 6.69167 1 6C1 5.30833 1.13133 4.65833 1.394 4.05C1.65667 3.44167 2.01283 2.9125 2.4625 2.4625C2.9125 2.0125 3.44167 1.65633 4.05 1.394C4.65833 1.13167 5.30833 1.00033 6 1C6.69167 1 7.34167 1.13133 7.95 1.394C8.55833 1.65667 9.0875 2.01283 9.5375 2.4625C9.9875 2.9125 10.3438 3.44167 10.6065 4.05C10.8692 4.65833 11.0003 5.30833 11 6C11 6.69167 10.8687 7.34167 10.606 7.95C10.3433 8.55833 9.98717 9.0875 9.5375 9.5375C9.0875 9.9875 8.55833 10.3438 7.95 10.6065C7.34167 10.8692 6.69167 11.0003 6 11Z" fill="#B10207" />
                            </svg>
                            Неверные логин или пароль</div>}
                        {status === 3 && <div className="auth-bad">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 8.5C6.14167 8.5 6.2605 8.452 6.3565 8.356C6.4525 8.26 6.50033 8.14133 6.5 8C6.5 7.85833 6.452 7.7395 6.356 7.6435C6.26 7.5475 6.14133 7.49967 6 7.5C5.85833 7.5 5.7395 7.548 5.6435 7.644C5.5475 7.74 5.49967 7.85867 5.5 8C5.5 8.14167 5.548 8.2605 5.644 8.3565C5.74 8.4525 5.85867 8.50033 6 8.5ZM5.5 6.5H6.5V3.5H5.5V6.5ZM6 11C5.30833 11 4.65833 10.8687 4.05 10.606C3.44167 10.3433 2.9125 9.98717 2.4625 9.5375C2.0125 9.0875 1.65633 8.55833 1.394 7.95C1.13167 7.34167 1.00033 6.69167 1 6C1 5.30833 1.13133 4.65833 1.394 4.05C1.65667 3.44167 2.01283 2.9125 2.4625 2.4625C2.9125 2.0125 3.44167 1.65633 4.05 1.394C4.65833 1.13167 5.30833 1.00033 6 1C6.69167 1 7.34167 1.13133 7.95 1.394C8.55833 1.65667 9.0875 2.01283 9.5375 2.4625C9.9875 2.9125 10.3438 3.44167 10.6065 4.05C10.8692 4.65833 11.0003 5.30833 11 6C11 6.69167 10.8687 7.34167 10.606 7.95C10.3433 8.55833 9.98717 9.0875 9.5375 9.5375C9.0875 9.9875 8.55833 10.3438 7.95 10.6065C7.34167 10.8692 6.69167 11.0003 6 11Z" fill="#B10207" />
                            </svg>
                            Сервис временно недоступен</div>}
                        {status === 5 &&
                            <div className="auth-bad err-auth-bad">{errorMess}!</div>}
                        <form className="login-page__form" onSubmit={submitForm}>
                            <div className="login-page__input-wrapper">
                                <label className='login-label' htmlFor="email">E-mail<span style={{ color: "#b10207" }}>*</span></label>
                                <input className={`login-page__input ${status === 1 ? 'error-login' : ''}`} type="email" name="email" id='email' onChange={(ev) => setEmail(ev.target.value)} />
                            </div>
                            <div className="login-page__input-wrapper login-page__password-wrapper">
                                <PasswordInput
                                    value={password}
                                    onChange={handlePasswordChange}
                                    className={`input-password__field ${status === 1 ? 'error-login' : ''}`}
                                />
                            </div>

                            <Link to="/login/forgot-password" className='forgot-password-link'>Забыли пароль?</Link>

                            <Toggle labelLeft={<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.18 18.98L20.345 9.815L18.525 7.995L11.18 15.34L7.475 11.635L5.655 13.455L11.18 18.98ZM13 26C11.2017 26 9.51167 25.6585 7.93 24.9756C6.34833 24.2927 4.9725 23.3666 3.8025 22.1975C2.6325 21.0275 1.70647 19.6517 1.0244 18.07C0.342333 16.4883 0.000866667 14.7983 0 13C0 11.2017 0.341467 9.51167 1.0244 7.93C1.70733 6.34833 2.63337 4.9725 3.8025 3.8025C4.9725 2.6325 6.34833 1.70647 7.93 1.0244C9.51167 0.342333 11.2017 0.000866667 13 0C14.7983 0 16.4883 0.341467 18.07 1.0244C19.6517 1.70733 21.0275 2.63337 22.1975 3.8025C23.3675 4.9725 24.294 6.34833 24.9769 7.93C25.6598 9.51167 26.0009 11.2017 26 13C26 14.7983 25.6585 16.4883 24.9756 18.07C24.2927 19.6517 23.3666 21.0275 22.1975 22.1975C21.0275 23.3675 19.6517 24.294 18.07 24.9769C16.4883 25.6598 14.7983 26.0009 13 26Z" fill="white" />
                            </svg>
                            }
                                labelRight={<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.18 18.98L20.345 9.815L18.525 7.995L11.18 15.34L7.475 11.635L5.655 13.455L11.18 18.98ZM13 26C11.2017 26 9.51167 25.6585 7.93 24.9756C6.34833 24.2927 4.9725 23.3666 3.8025 22.1975C2.6325 21.0275 1.70647 19.6517 1.0244 18.07C0.342333 16.4883 0.000866667 14.7983 0 13C0 11.2017 0.341467 9.51167 1.0244 7.93C1.70733 6.34833 2.63337 4.9725 3.8025 3.8025C4.9725 2.6325 6.34833 1.70647 7.93 1.0244C9.51167 0.342333 11.2017 0.000866667 13 0C14.7983 0 16.4883 0.341467 18.07 1.0244C19.6517 1.70733 21.0275 2.63337 22.1975 3.8025C23.3675 4.9725 24.294 6.34833 24.9769 7.93C25.6598 9.51167 26.0009 11.2017 26 13C26 14.7983 25.6585 16.4883 24.9756 18.07C24.2927 19.6517 23.3666 21.0275 22.1975 22.1975C21.0275 23.3675 19.6517 24.294 18.07 24.9769C16.4883 25.6598 14.7983 26.0009 13 26Z" fill="white" />
                                </svg>
                                }
                                // defaultChecked={toggleStates[cart.id]}
                                onChange={handleSellerChange}
                                textLeft="Покупатель"
                                textRight="Продавец"
                                setUserType={setUserType} />
                            <div className="login-page__input-wrapper input-container">
                                <input className="login-page__btn" type="submit" value="Войти" />
                            </div>
                        </form>
                        <div className='login__registration-wrapp'>
                            <span className='login__registration-text'>Ещё нет аккаунта?</span>

                            <a href='/registration' className='login__registration-link'>Зарегистрироваться</a>
                            {/* <button className='login__registration-link' onClick={handleLoading}>Зарегистрироваться</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Login } from './pages/Login/Login';
import { NotFound } from './components/NotFound/NotFound';
import { ForgotPassword } from './pages/Login/ForgotPassword';
import './scss/normalize.scss';

export function App() {
  return (
    <>
    <Routes>
      <Route path='/login' element={<Layout/>}>
        <Route index element={<Login/>}/>
        <Route path='/login/forgot-password' element={<ForgotPassword/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Route>
    </Routes>
    </>
  );
}


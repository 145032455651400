import React, { useState } from 'react';
import image from '../../img/login-back.png';
import '../Login/Login.scss';
import { useNavigate } from 'react-router-dom';
import { forgotPass } from '../../helpers/api';
import Toggle from '../../components/Toggle/Toggle';
import { Modal } from '../../components/Modal/Modal';
import { Loader } from '../../components/Loader/Loader';
// import Cookies from 'js-cookie';

export function ForgotPassword() {

    // Toggle

    const [userType, setUserType] = useState(1);
    const [isSeller, setIsSeller] = useState(false);

    const handleSellerChange = (value: any) => {
        setIsSeller(value);
        setStatus(0)
    };

    const [status, setStatus] = useState<number>(0);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const user = {
        // id: 1,
        email: email,
        user_type: userType,
    }


    const submitForm = (event: any) => {
        event.preventDefault()
        const formDate = new FormData(event.target);
        formDate.append('user_type', String(userType));

        if (!email) {
            setStatus(1);
            return;
        }

        setLoading(true);

        forgotPass(formDate).then((response) => {
            if (response.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                    setStatus(2);
                    setIsOpenModal(true);
                    setEmail('');
                }, 600)
            }
        })
            .catch(e => {
                if (e.response.status && e.response.status === 500) {
                    setStatus(3)
                    setLoading(false);
                    return
                } else {
                    setStatus(1)
                    setLoading(false);
                    return
                }
            })
    }

    return (
        <section className='login'>
            {loading && <Loader loading={loading} />}
            <div className='login__container'>
                <div className='login-wrapp-right'>
                    <img src={image} alt="" />
                </div>
                <div className='login-wrapp-left'>
                    <div className='form-wrapp'>
                        <h1 className='login__title'>Забыли пароль?</h1>
                        <span className='forgot-text'>Укажите e-mail, который Вы использовали для входа на сайт</span>
                        {status === 3 && <div className="auth-bad auth-bad-forgot">Сервис временно недоступен!</div>}
                        {status === 4 && <div className="auth-bad auth-bad-forgot">Что-то пошло не так!</div>}
                        {status === 1 && <div className="auth-bad auth-bad-forgot">Введите e-mail!</div>}

                        <form className="login-page__form" onSubmit={submitForm}>
                            <div className="login-page__input-wrapper">
                                <label className='login-label' htmlFor="email">E-mail<span style={{ color: "#b10207" }}>*</span></label>
                                <input className={`login-page__input ${status === 1 ? 'error-login' : ''}`} type="email" name="email" id='email' onChange={(ev) => setEmail(ev.target.value)} />
                            </div>

                            <Toggle labelLeft={<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.18 18.98L20.345 9.815L18.525 7.995L11.18 15.34L7.475 11.635L5.655 13.455L11.18 18.98ZM13 26C11.2017 26 9.51167 25.6585 7.93 24.9756C6.34833 24.2927 4.9725 23.3666 3.8025 22.1975C2.6325 21.0275 1.70647 19.6517 1.0244 18.07C0.342333 16.4883 0.000866667 14.7983 0 13C0 11.2017 0.341467 9.51167 1.0244 7.93C1.70733 6.34833 2.63337 4.9725 3.8025 3.8025C4.9725 2.6325 6.34833 1.70647 7.93 1.0244C9.51167 0.342333 11.2017 0.000866667 13 0C14.7983 0 16.4883 0.341467 18.07 1.0244C19.6517 1.70733 21.0275 2.63337 22.1975 3.8025C23.3675 4.9725 24.294 6.34833 24.9769 7.93C25.6598 9.51167 26.0009 11.2017 26 13C26 14.7983 25.6585 16.4883 24.9756 18.07C24.2927 19.6517 23.3666 21.0275 22.1975 22.1975C21.0275 23.3675 19.6517 24.294 18.07 24.9769C16.4883 25.6598 14.7983 26.0009 13 26Z" fill="white" />
                            </svg>
                            }
                                labelRight={<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.18 18.98L20.345 9.815L18.525 7.995L11.18 15.34L7.475 11.635L5.655 13.455L11.18 18.98ZM13 26C11.2017 26 9.51167 25.6585 7.93 24.9756C6.34833 24.2927 4.9725 23.3666 3.8025 22.1975C2.6325 21.0275 1.70647 19.6517 1.0244 18.07C0.342333 16.4883 0.000866667 14.7983 0 13C0 11.2017 0.341467 9.51167 1.0244 7.93C1.70733 6.34833 2.63337 4.9725 3.8025 3.8025C4.9725 2.6325 6.34833 1.70647 7.93 1.0244C9.51167 0.342333 11.2017 0.000866667 13 0C14.7983 0 16.4883 0.341467 18.07 1.0244C19.6517 1.70733 21.0275 2.63337 22.1975 3.8025C23.3675 4.9725 24.294 6.34833 24.9769 7.93C25.6598 9.51167 26.0009 11.2017 26 13C26 14.7983 25.6585 16.4883 24.9756 18.07C24.2927 19.6517 23.3666 21.0275 22.1975 22.1975C21.0275 23.3675 19.6517 24.294 18.07 24.9769C16.4883 25.6598 14.7983 26.0009 13 26Z" fill="white" />
                                </svg>
                                }
                                // defaultChecked={toggleStates[cart.id]}
                                onChange={handleSellerChange}
                                textLeft="Покупатель"
                                textRight="Продавец"
                                setUserType={setUserType} />
                            <div className="login-page__input-wrapper input-container">
                                <input className="login-page__btn" type="submit" value="Отправить" />
                            </div>
                        </form>
                        {/* {status === 2 && 
                        // <div className="auth-success">Авторизация выполнена успешно</div>
                        <Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
                        <div className='modal-registration'>
                            <h2 className='modal-registration__title'>Спасибо!</h2>
                            <h3 className='modal-registration__text'>Мы отправили Вам ссылку для восстановления пароля на указанный Вами адрес электронной почты. Пожалуйста, проверьте свою почту и следуйте инструкциям для восстановления пароля.</h3>
                        </div>
                        </Modal>} */}
                        {/* {status === 1 && <div className="auth-bad">Не верные логин или пароль</div>}
                        {status === 3 && <div className="auth-bad">Сервис временно недоступен</div>} */}
                    </div>
                </div>
            </div>
            {status === 2 &&
                // <div className="auth-success">Авторизация выполнена успешно</div>
                <Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal}>
                    <button type="button" className="btn-close-modal" onClick={() => setIsOpenModal(false)}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 10L30 30" stroke="#31465A" strokeWidth="3" />
                            <path d="M10 30L30 10" stroke="#31465A" strokeWidth="3" />
                        </svg>
                    </button>
                    <div className='modal-registration'>
                        <h2 className='modal-registration__title'>Спасибо!</h2>
                        <h3 className='modal-registration__text'>Мы отправили Вам ссылку для восстановления пароля на указанный Вами адрес электронной почты. Пожалуйста, проверьте свою почту и следуйте инструкциям для восстановления пароля.</h3>
                    </div>
                </Modal>
            }
        </section>
    )
} 